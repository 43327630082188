import {GalleryQueryParams, WixQueryParams} from '../../constants';

export const getQueryParamsDiff = ({
  currentQueryParams,
  newQueryParams,
  filterQueryParamsKeys,
}: {
  currentQueryParams: {[key: string]: any};
  newQueryParams: {
    [key: string]: any;
  };
  filterQueryParamsKeys: string[];
}): {
  isPageUpdated: boolean;
  isSortUpdated: boolean;
  isFiltersUpdated: boolean;
  numberOfKnownChangedKeysTypes: number;
} => {
  const knownQueryParamsKeys = [
    ...filterQueryParamsKeys,
    WixQueryParams.Lang,
    WixQueryParams.Currency,
    GalleryQueryParams.Sort,
    GalleryQueryParams.Page,
  ];

  const currentKnownQueryParams = extractKnownParams(currentQueryParams, knownQueryParamsKeys);
  const newKnownQueryParams = extractKnownParams(newQueryParams, knownQueryParamsKeys);

  const changedKeys = new Set<string>();

  for (const key in currentQueryParams) {
    if (currentKnownQueryParams[key] !== newKnownQueryParams[key]) {
      changedKeys.add(key);
    }
  }

  for (const key in newKnownQueryParams) {
    if (!(key in currentKnownQueryParams)) {
      changedKeys.add(key);
    }
  }

  const changedKeysArray = Array.from(changedKeys);
  const isPageUpdated = changedKeysArray.includes(GalleryQueryParams.Page);
  const isSortUpdated = changedKeysArray.includes(GalleryQueryParams.Sort);
  const isFiltersUpdated = changedKeysArray.some((key) => filterQueryParamsKeys.includes(key));
  const numberOfKnownChangedKeysTypes = [isPageUpdated, isSortUpdated, isFiltersUpdated].filter(Boolean).length;

  return {
    isPageUpdated,
    isSortUpdated,
    isFiltersUpdated,
    numberOfKnownChangedKeysTypes,
  };
};

export const extractKnownParams = (queryParams: {[key: string]: string}, knownQueryParams: string[]) =>
  knownQueryParams.reduce((acc, key) => {
    if (queryParams[key]) {
      acc[key] = queryParams[key];
    }
    return acc;
  }, {});
